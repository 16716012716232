










































































































































































































































































































import { errorNotification, successNotification } from '@/includes/NotificationService'

enum ScheduleType {
  PostNow = 'PostNow',
  Draft = 'Draft',
  Test = 'Test'
}

import {
  Board,
  Conditions, IPost,
  MessageVariants, Poll,
  PostData, Quiz,
  Schedule,
  Style,
} from '@/views/posts-planner/posts.types'
import Api from '@/includes/logic/Api'
import { InputSetups } from '@/mixins/input-setups'
import PeriodSettings from '@/views/posts-planner/components/Post/Period/PeriodSettings.vue'
import PostStylesSetup from '@/views/posts-planner/components/PostStylesSetup.vue'
import ActionList from '@/views/posts-planner/components/Post/Actions/ActionList.vue'
import AccordionWrapper from '@/views/posts-planner/components/Post/AccordionWrapper.vue'
import ReactionsList from '@/views/posts-planner/components/Post/Reactions/ReactionsList.vue'
import Quizes from '@/views/posts-planner/components/Quizes.vue'
import { BRAND_TAG } from '@/includes/constants';

import { timezoneList } from 'piramis-base-components/src/logic/timizone-list'
import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'
import Accordion from 'piramis-base-components/src/components/Accordion/Accordion.vue'
import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { SelectOption } from 'piramis-base-components/src/logic/types'
import Tags from 'piramis-base-components/src/components/Tags/Tags.vue'
import { MessageEditorWithMediaData } from 'piramis-base-components/src/components/MessageEditorWithMedia/types'
import { EntityTypes } from 'piramis-base-components/src/components/SelectEntityWizard/includes/types'
import { FileType } from 'piramis-base-components/src/components/File/types'

import { Mixins, Watch } from 'vue-property-decorator'
import Component from 'vue-class-component'
import { cloneDeep } from 'lodash'
import moment from 'moment'

@Component({
  'components': {
    Quizes,
    ReactionsList,
    PeriodSettings,
    ConfigField,
    Accordion,
    PostStylesSetup,
    ActionList,
    AccordionWrapper,
    Tags,
  },
  data() {
    return {
      FileType
    }
  }
})
export default class Post extends Mixins(InputSetups, UseFields) {
  BRAND_TAG = BRAND_TAG

  postType = 'Post' as 'Poll' | 'Post' | 'Quiz'

  scheduleType = '' as ScheduleType

  arePostActionSet = false

  arePostReactionSet = false

  isObjectSet = false

  selectedStyle = {}

  rerenderPostStylesSetup = 0

  areOriginsOpen = false

  isExtendedSettingsOpen = false

  initConditions: Conditions[] = []

  boardTokenIdCode: string = ''

  postPageActionType: any = ''

  selectOptionsChats: Array<any> = []

  originSelectRerenderKey = 0

  targetSelectRerenderKey = 0

  run_time: any = {
    'date': moment().add('1', 'd').format('YYYY-MM-DD'),
    'time': moment().add('1', 'h').format('HH:mm:ss'),
  }

  styleVariant = 'empty'

  selectStyleVariants: Array<SelectOption> =
    [
      { 'label': this.$t('post_page_style_empty'), 'value': 'empty' },
      { 'label': this.$t('post_page_style_blank'), 'value': 'blank' },
    ]

  isCreateButtonDisabled = true

  boardTokenCode = ''

  model: PostData = {
    post: {
      message: {
        type: this.postType,
        variants: [ ...this.initializeVariants ]
      } as any,
      delayed_actions: [],
      reaction_actions: [],
    },
    schedule: {
      period: {
        conditions: [],
        unit: 'MINUTES',
        interval: 0
      } || null,
      timezone: '',
      run_time: ''
    },
    targets: [],
    origin: null,
    style: {}
  }

  modelCopy: any

  time = moment().utc().format('HH:mm')

  intervalId = 0

  get topicsSettings():any {
    if (this.model.targets?.length === 1) {
      if (this.model.targets?.every(t => this.$store.getters.topicsStructureIfForum(this.$route.params.boardId, t) !== null)) {
        return {
          'items': this.$store.getters.topicsIfForum(this.$route.params.boardId, this.model.targets[0]),
          'limit': 1
        }
      }

      return undefined
    }

    return undefined
  }

  @Watch('postType')
  onPostTypeChange(value: 'Poll' | 'Post' | 'Quiz') {
    if (this.modelCopy && this.modelCopy.post.message.type === this.postType) {
      this.model = cloneDeep(this.modelCopy)
    } else {
      this.model.post.message = {} as any
      const postQuestionaire = {
        type: this.postType,
        text: '',
        open_period: 0,
        is_anonymous: true,
        questions: [],
        topics: []
      }
      this.model.post.message.type = value
      if (this.model.post.message.type === 'Post') {
        this.$set(this.model.post.message, 'variants', this.initializeVariants)
      } else if (this.model.post.message.type === 'Poll') {
        this.model.post.message = { ...postQuestionaire, allows_multiple_answers: true } as Poll
      } else {
        this.model.post.message = { ...postQuestionaire, correct_option_id: '', explanation: '' } as Quiz
      }
    }
  }

  @Watch('model.post.message.variants')
  onMediaEditorChange(value: any[]) {
    if (value) {
      this.isCreateButtonDisabled = true
      value.forEach((m: any) => {
        if (!m.text.trim() && !m.attachments.length) {
          this.isCreateButtonDisabled = true
        } else if (m.text || m.attachments.length) {
          this.isCreateButtonDisabled = false
          if (m.attachments.length) {
            m.attachments.forEach((a: any) => {
              a.status === 0 ? this.isCreateButtonDisabled = true : this.isCreateButtonDisabled = false
            })
          }
        } else {
          this.isCreateButtonDisabled = false
        }
      })
    }
  }

  @Watch('model.targets')
  onModelTargetChange() {
    if (this.model.targets && this.model.origin && this.model.targets.includes(this.model.origin)) {
      this.model.origin = null
    }

    this.originSelectRerenderKey += 1
    this.targetSelectRerenderKey += 1
  }

  handleDropdownItem(action: ScheduleType): void {
    if (action === ScheduleType.PostNow) {
      this.scheduleType = action
      this.createPost()
    }
    if (action === ScheduleType.Test) {
      this.scheduleType = action
      Api.testPost('tg', {
        board: this.boardTokenIdCode,
        post: { ...this.getPost().message }
      }).then(() => {
        successNotification()
      })
    }
    if (action === ScheduleType.Draft && this.isBoardUltimate) {
      this.scheduleType = action
      this.createPost()
    }
  }

  modelSetter(args: FieldData): FieldData {
    if (this.model.style === undefined) {
      this.model.style = {}
    }
    args.setter = (value: any): void => {
      this.$set(args.model, args.key as string, value)
    }
    return args
  }

  targetOptions(): any {
    return this.selectOptionsChats
  }

  originOptions(): any {
    return this.selectOptionsChats.slice().filter((o: any) => this.model.targets && !this.model.targets.includes(o.value))
  }

  getTimezoneList(): Promise<any> {
    return Promise.resolve(timezoneList)
  }

  onSelectedStyleChange(selectedStyle: SelectOption): void {
    if (selectedStyle.value !== 'blank' && selectedStyle.value !== 'empty') {
      this.model.style = { ...selectedStyle.value }
    } else {
      this.model.style = {
        postfix_message: [ ...this.initializeVariants ],
        prefix_message: [ ...this.initializeVariants ],
        watermark_data: ''
      }
      this.selectedStyle = {}
    }
    this.rerenderPostStylesSetup += 1
  }

  editPost() {
    Api.deletePost('tg', { 'board': this.boardTokenIdCode, 'key': this.$route.query.postId })
      .then(() => {
        this.createPost()
      })
      .catch(errorNotification)
  }

  createPost(): void {
    this.preparePostData()
      .then((data: any) => {
        Api.createPost('tg', data)
          .then(() => {
            successNotification()
          })
          .catch(errorNotification)
          .finally(() => {
            this.$baseTemplate.loader.close()
            this.$router.push({
              name: 'posts_planner',
              params: {
                id: this.$route.params[EntityTypes.CHAT_ID],
                boardId: this.$route.params.boardId,
              }
            })
          })
      })
  }

  parseStyle(style: Style | null | undefined): Style {
    if (this.styleVariant !== 'empty') {
      const styleCopy = { ...style }
      if (this.model.style?.watermark_type === 'File') {
        styleCopy.watermark_data = styleCopy.watermark_data.join()
      }
      return styleCopy
    } else {
      return {}
    }
  }

  preparePostData(): Promise<any> {
    return new Promise(resolve => {
      if (this.scheduleType === ScheduleType.Draft) {
        const postData = {
          board: this.boardTokenIdCode,
          ...this.getPost()
        }
        resolve(postData)
      } else {
        const postData: PostData = {
          board: this.boardTokenIdCode,
          targets: this.model.targets,
          schedule: this.getSchedule(),
          post: this.getPost(),
          style: this.parseStyle(this.model.style),
          origin: this.areOriginsOpen && this.model.origin ? this.model.origin : null
        }
        if (this.scheduleType === ScheduleType.PostNow) delete postData.schedule
        resolve(postData)
      }
    })
  }

  getSchedule(): Schedule | null {
    if (this.scheduleType === ScheduleType.PostNow || this.scheduleType === ScheduleType.Draft) {
      return null
    } else {
      return {
        run_time: this.getRunTime(),
        period: this.model.schedule!.period?.interval ? {
          interval: Number(this.model.schedule!.period.interval),
          unit: this.model.schedule!.period!.unit,
          conditions: this.getConditions()
        } : null,
        timezone: this.model.schedule!.timezone
      }
    }
  }

  getConditions(): Array<Conditions> | null {
    return this.model!.schedule!.period!.conditions ? this.model!.schedule!.period!.conditions : null
  }

  getRunTime(): string {
    return `${ this.run_time.date } ${ this.run_time.time }`
  }

  getPost(): IPost {
    let resPostObject = {
      message: {
        type: this.model.post.message.type,
      } as any,
      delayed_actions: this.model.post.delayed_actions,
      reaction_actions: this.model.post.reaction_actions,
    }

    if (this.model.post.message.type === 'Post') {
      resPostObject.message = { ...resPostObject.message, variants: this.getVariants() }
    } else {
      resPostObject.message = { ...resPostObject.message, ...this.model.post.message }
      resPostObject.message.open_period = resPostObject.message.open_period === 0 ? null : resPostObject.message.open_period
    }
    return resPostObject as IPost
  }

  getVariants(): Array<MessageVariants> {
    if (this.model.post.message.type === 'Post') {
      return this.model.post.message.variants
    } else {
      return []
    }
  }

  isButtonDisabled(scheduleType?: ScheduleType): boolean {
    if (scheduleType === ScheduleType.PostNow) {
      if (this.model.post.message.type === 'Poll') {
        return !this.model.targets!.length ||
          Object.values(this.run_time).includes('') ||
          this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === 'Quiz') {
        return !this.model.targets!.length ||
          Object.values(this.run_time).includes('') ||
          !this.model.post.message.correct_option_id ||
          this.mainFieldsQuizValidation
      }
      return Object.values(this.run_time).includes('') ||
        !this.model.targets!.length ||
        this.isCreateButtonDisabled
    }

    if (scheduleType === ScheduleType.Draft || scheduleType === ScheduleType.Test) {
      if (this.model.post.message.type === 'Poll') {
        return this.mainFieldsQuizValidation
      }
      if (this.model.post.message.type === 'Quiz') {
        return !this.model.post.message.correct_option_id ||
          this.mainFieldsQuizValidation
      }
      return this.isCreateButtonDisabled
    }

    if (this.model.post.message.type === 'Post') {
      return Object.values(this.run_time).includes('') ||
        !this.model.targets!.length ||
        this.isCreateButtonDisabled
    }
    if (this.model.post.message.type === 'Poll') {
      return Object.values(this.run_time).includes('') ||
        !this.model.targets!.length ||
        this.mainFieldsQuizValidation
    }
    if (this.model.post.message.type === 'Quiz') {
      return Object.values(this.run_time).includes('') ||
        !this.model.targets!.length ||
        !this.model.post.message.correct_option_id ||
        this.mainFieldsQuizValidation
    }
    return true
  }

  get mainFieldsQuizValidation(): boolean {
    if (this.model.post.message.type === 'Quiz' || this.model.post.message.type === 'Poll') {
      return !this.model.post.message.questions.length ||
        !this.model.post.message.text ||
        this.model.post.message.open_period > 600
    }
    return true
  }

  get dropdownScheduleTypes(): Array<any> {
    return [
      {
        'key': 'post_publish_test',
        'icon': 'tool',
        'action': ScheduleType.Test
      },
      {
        'key': 'post_post_now',
        'icon': 'schedule',
        'action': ScheduleType.PostNow
      },
      {
        'key': 'post_save_draft',
        'icon': 'folder',
        'action': ScheduleType.Draft,
      }
    ]
  }

  get initializeVariants(): Array<MessageEditorWithMediaData> {
    return [ {
      attachments: [],
      text: '',
      buttons: [],
      remove_previous: false,
      pin: false,
      disable_link_preview: false,
      disable_notify: false,
      topics: [],
    } ]
  }

  get isBoardUltimate(): boolean {
    return this.boardTokenCode !== 'default'
  }

  fetchConfigStyles(board: string): void {
    Api.getBoardConfig('tg', { board })
      .then(({ data }) => {
        if (data.config.styles) {
          const styles = { ...data.config.styles } as Record<any, Style>
          for (const [ key, value ] of Object.entries(styles)) {
            if (value.watermark_type === 'File') {
              value.watermark_data = value.watermark_data.split()
            }
            this.selectStyleVariants.push({ label: key, value: value })
          }
        }
      })
      .catch(errorNotification)
  }

  setPostType(type: string): void {
    switch (type) {
      case 'Quiz':
        this.postType = 'Quiz'
        break
      case 'Poll':
        this.postType = 'Poll'
        break
      default:
        this.postType = 'Post'
        break
    }
  }

  getSavedPosts(): void {
    Api.getSavedPosts('tg', { board: this.boardTokenIdCode })
      .then(({ data }) => {
        const draft = data.posts.find((p: any) => p.key.token_id === Number(this.$route.query.draft))
        this.setPostType(draft.message.type)
        this.model.post.message = { ...draft.message }
        this.modelCopy = cloneDeep(this.model)

      })
      .then(() => {
        this.isObjectSet = true
        this.modelCopy = cloneDeep(this.model)
      })
      .finally(() => this.$baseTemplate.loader.close())
  }

  getPostById(currentBoard: Board): void {
    Api.getPosts('tg', {
      board: this.boardTokenIdCode,
      timezone: currentBoard.timezone
    })
      .then(({ data }) => {
        const postKey = data.posts.find((p: PostData) => p.key === this.$route.query.postId).key
        Api.getOriginalPost('tg', { board: this.boardTokenIdCode, key: postKey })
          .then(({ data }) => {
            const editItem = data.post
            const postRunTime = editItem.schedule.run_time.split(' ')

            this.run_time.date = postRunTime[0]
            this.run_time.time = postRunTime[1]

            this.setPostType(editItem.message.message.type)

            this.model.post.message = { ...editItem.message.message }
            if (editItem.message.delayed_actions && editItem.message.delayed_actions.length) {
              this.model.post.delayed_actions = [ ...editItem.message.delayed_actions ]

              this.arePostActionSet = true
            }
            if (editItem.message.reaction_actions && editItem.message.reaction_actions.length) {
              this.model.post.reaction_actions = [ ...editItem.message.reaction_actions ]

              this.arePostReactionSet = true
            }

            this.model.schedule = { ...editItem.schedule }
            this.model.targets = editItem.target.targets

            if (editItem.target.original !== null) {
              this.areOriginsOpen = true
              this.model.origin = editItem.target.original
            }

            this.isCreateButtonDisabled = false
            if (editItem.schedule.period !== null || (editItem.style && Object.values(editItem.style).some(s => s !== null && s !== 'None'))) {
              this.isExtendedSettingsOpen = true
              if (editItem.schedule.period && editItem.schedule.period.conditions) {
                this.initConditions = editItem.schedule.period.conditions
              }
              if (editItem.style !== null && Object.values(editItem.style).some(s => s !== null && s !== 'None')) {
                this.styleVariant = 'blank'
                if (editItem.style.watermark_type === 'File') {
                  editItem.style.watermark_data = editItem.style.watermark_data.split()
                }
                this.model.style = { ...editItem.style }
              }
            }
            this.modelCopy = cloneDeep(this.model)
          })
          .finally(() => {
            this.isObjectSet = true
            this.$baseTemplate.loader.close()
          })
      })
      .catch(errorNotification)
  }

  mounted(): void {
    if (this.$route.query['post_date']) this.run_time.date = this.$route.query['post_date']
    if (this.$route.query['post_time']) this.run_time.time = this.$route.query['post_time']
    if (this.$route.params['actionType']) this.postPageActionType = this.$route.params['actionType']

    const currentBoard:Board = this.$store.state.postsPlanner.boards.find((b: Board) => b.board.token_id === Number(this.$route.params['boardId']))
    this.boardTokenCode = currentBoard.board.token_code
    this.boardTokenIdCode = `${ currentBoard.board.token_id }.${ currentBoard.board.token_code }`

    for (const target of currentBoard.targets) {
      this.selectOptionsChats.push({
        'label': target.title,
        'value': target.id,
        'image': {
          'src': target.photo
        },
      })
    }

    if (this.selectOptionsChats.length === 1) {
      this.model.targets = [ currentBoard.targets[0].id ]
    }

    this.model.schedule ? this.model.schedule.timezone = currentBoard.timezone : this.model.schedule = null
    this.fetchConfigStyles(this.boardTokenIdCode)

    if (this.$route.query.draft) {
      this.$baseTemplate.loader.open()
      this.getSavedPosts()
    } else {
      if (this.$route.query.postId) {
        this.$baseTemplate.loader.open()
        this.getPostById(currentBoard)
      } else {
        this.isObjectSet = true
        this.$baseTemplate.loader.close()
      }
    }

    this.intervalId = setInterval(() => {
      this.time = moment().utc().format('HH:mm')
    }, 1000)
  }

  destroyed(): void {
    clearInterval(this.intervalId)
  }
}
