



































import { Prop, VModel } from "vue-property-decorator";
import Component from "vue-class-component";
import {
  IPostActionMessage,
  MessageVariants,
  PostActionType,
  PostMessage,
  PostType
} from '@/views/posts-planner/posts.types'
import Vue from "vue";
import { cloneDeep } from 'lodash'

@Component({})
export default class PostActionTypePopup extends Vue {

  @VModel() isPopupActive !: boolean

  @Prop({ required: true }) postMessage !: any

  @Prop({ type: String }) postType !: PostType

  postActionsList: Array<IPostActionMessage & { key: string, label: string }> = []

  get postActions(): Array<IPostActionMessage & { label: string, icon: string }> {
    return [ {
               label: this.$t('post_action_send_comment').toString(),
               icon: '<span class="material-icons">add_comment</span>',
               action: {
                 type: PostActionType.SendCommentAction,
                 message: {
                   type: "Post",
                   variants: [
                     {
                       "pin": false,
                       "text": "",
                       "buttons": [],
                       "disable_notify": false,
                       "remove_previous": false,
                       "disable_link_preview": false,
                       "attachments": []
                     }
                   ]
                 }
               }
             },
             ...this.postMessage.type === 'Post' ? [ {
               label: this.$t('post_action_edit_post').toString(),
               icon: '<span class="material-icons">edit_note</span>',
               action: {
                 type: PostActionType.EditPostAction,
                 message: this.purePostMessages(this.postMessage)
               },
             } ] : [],
             {
               label: this.$t('post_action_delete_post').toString(),
               icon: '<span class="material-icons">delete_sweep</span>',
               action: {
                 type: PostActionType.DeletePostAction
               },
             },
             {
               label: this.$t('post_action_unpin_message').toString(),
               icon: '<span class="material-icons">push_pin</span>',
               action: {
                 type: PostActionType.UnpinPostAction
               },
             }
    ]
  }

  purePostMessages(messages: PostMessage): PostMessage {
    let variants = cloneDeep(messages)
    variants.variants = variants.variants.map((v: MessageVariants) => {
      delete v.attachments
      return v
    })
    return variants
  }

}
