
























import Vue from "vue";
import Component from "vue-class-component";
import { Prop } from "vue-property-decorator";

@Component
export default class AccordionWrapper extends Vue {

  @Prop({ default: false, required: false, type: Boolean }) isSlideOpen !: boolean

  data() {
    return {
      slideOpen:this.isSlideOpen
    }
  }

  onSlideCloseStart(e: Event) {
    (<HTMLElement>e.target).classList.remove("overflow-visible");
    (<HTMLElement>e.target).classList.add("overflow-hidden");
  }

  onSlideOpenEnd(e: Event) {
    (<HTMLElement>e.target).classList!.remove("overflow-hidden");
    (<HTMLElement>e.target).classList!.add("overflow-visible");
  }

}
