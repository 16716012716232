





















































import ActionItem from "@/views/posts-planner/components/Post/Actions/ActionItem.vue";
import { IPostActionMessage, PostAction } from "@/views/posts-planner/posts.types";
import PostActionTypePopup from "@/views/posts-planner/components/Post/PostActionTypePopup.vue";

import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";
import { cloneDeep } from 'lodash'
import Vue from "vue";

@Component({
  components: {
    PostActionTypePopup,
    ActionItem,
  }
})
export default class ActionList extends Vue {

  @VModel() actions !: Array<PostAction>

  @Prop({ required: true, type: String }) board !: string

  @Prop({ required: true }) postMessage !: any

  actionsCopy: Array<PostAction> = []

  rerenderKey = 0

  areActionTypesOpen = false

  @Watch('actionsCopy', { deep: true })
  onActionsCopyChange(value: any) {
    this.actions = cloneDeep(value).map((a: any) => {
      delete a.key
      delete a.label
      return a
    })
    this.areActionTypesOpen = false
  }

  removeAction(index: number): void {
    this.actionsCopy.splice(index, 1)
    this.rerenderKey += 1;
  }

  addAction(action: IPostActionMessage): void {
    const actionCopy = cloneDeep(action)
    this.actionsCopy.push({ ...actionCopy, delay: 1 } as PostAction)
  }

  moveActionItemUp(index: number): void {
    const action = this.actionsCopy[index]
    this.actionsCopy.splice(index, 1)
    this.actionsCopy.splice(index - 1, 0, action)
    this.rerenderKey += 1
  }

  moveActionItemDown(index: number): void {
    const action = this.actionsCopy[index]
    this.actionsCopy.splice(index, 1)
    this.actionsCopy.splice(index + 1, 0, action)
    this.rerenderKey += 1
  }

  created(): void {
    this.actionsCopy = this.actions
  }

}
