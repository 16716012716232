


























































































import { InputSetups } from '@/mixins/input-setups'
import { MessageVariants, PostActionType, PostReaction } from '@/views/posts-planner/posts.types'
import ActionTypesMixin from '@/views/posts-planner/ActionTypesMixin'
import { actionLabel } from '@/mixins/ActionLabels'

import { FieldData } from 'piramis-base-components/src/components/Pi/types'
import { UseFields } from 'piramis-base-components/src/components/Pi'
import { FileType } from 'piramis-base-components/src/components/File/types'

import Component from 'vue-class-component'
import { Mixins, Prop, VModel } from 'vue-property-decorator'

@Component({
  data() {
    return {
      actionLabel,
      FileType
    }
  }
})
export default class ReactionItem extends Mixins(ActionTypesMixin, UseFields, InputSetups) {

  @VModel() reaction!: PostReaction & { key: string, label: string }

  @Prop({ required: true, type: String }) board !: string

  @Prop({ type: Number }) itemIndex !: number

  @Prop({ required: true }) variants !: Array<MessageVariants>

  PostActionType: any = PostActionType

  selectedReaction = ''

  areReactionsOpen = false

  modelSetter(args: FieldData): FieldData {
    args.setter = (value: any): void => {
      args.model[args.key] = value
    }
    return args
  }

  get reactionList(): Array<{ text: string, guid: string }> {
    const variantsCopy = [ ...this.variants ]
    const reactionList = [] as Array<{ text: string, guid: string }>
    variantsCopy.forEach((variant: any) => {
      if (variant.buttons.length) {
        variant.buttons.forEach((buttons: any) => {
          buttons.forEach((buttonElem: any) => {
            reactionList.push({
              text: buttonElem.text,
              guid: buttonElem.guid
            })
          })
        })
      } else {
        return []
      }
    })
    return reactionList
  }

  created() {
    if (this.reaction) {
      this.variants.forEach((variant: any) => {
        if (variant.buttons.length) {
          variant.buttons.forEach((buttons: any) => {
            buttons.forEach((buttonElem: any) => {
              if (buttonElem.guid === this.reaction.reaction) {
                this.selectedReaction = buttonElem.text
              }
            })
          })
        }
      })
    }
  }

}
