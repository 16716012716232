var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"period-settings"},[_c('div',{staticClass:"time-unit-input wrapper m-2 mt-5 mb-5"},[_c('time-unit-input',{attrs:{"setup":{
        'func': _vm.modelSetter,
        'args': {
          'model': _vm.model.schedule.period,
          'key': 'interval',
          'step': _vm.StepUnit.Minute,
          'validation': 'required|min_value:1',
          'prefix': 'post_',
        },
      }}})],1),_c('period-conditions',{attrs:{"period":_vm.model.schedule.period,"initConditions":_vm.initConditions},model:{value:(_vm.scheduleCondition),callback:function ($$v) {_vm.scheduleCondition=$$v},expression:"scheduleCondition"}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }