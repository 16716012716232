
































import { IPostActionMessage, PostReaction } from "@/views/posts-planner/posts.types";
import ReactionItem from "@/views/posts-planner/components/Post/Reactions/ReactionItem.vue";
import PostActionTypePopup from "@/views/posts-planner/components/Post/PostActionTypePopup.vue";

import { cloneDeep } from "lodash";
import Vue from "vue";
import Component from "vue-class-component";
import { Prop, VModel, Watch } from "vue-property-decorator";

@Component({
  components: {
    ReactionItem,
    PostActionTypePopup
  }
})
export default class ReactionsList extends Vue {

  @VModel() reactions !: Array<PostReaction>

  @Prop({ required: true, type: String }) board !: string

  @Prop({ required: true }) postMessage !: any

  reactionsCopy: Array<PostReaction> = []

  rerenderKey = 0

  areActionTypesOpen = false

  @Watch('reactionsCopy', { deep: true })
  onReactionCopyChange(value: any) {
    this.reactions = cloneDeep(value).map((val: any) => {
      delete val.key
      delete val.label
      return val
    })
    this.areActionTypesOpen = false
  }

  removeReaction(index: number): void {
    this.reactionsCopy.splice(index, 1)
    this.rerenderKey += 1;
  }

  addReaction(action: IPostActionMessage): void {
    const actionCopy = cloneDeep(action)
    this.reactionsCopy.push({
      ...actionCopy,
      reaction: "",
      lower_bound: 1,
    })
  }

  created(): void {
    this.reactionsCopy = this.reactions
  }
}
