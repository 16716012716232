













































































































































































































import { Conditions, Period } from '@/views/posts-planner/posts.types'
import { InputSetups } from '@/mixins/input-setups'

import ConfigField from 'piramis-base-components/src/components/ConfigField/ConfigField.vue'
import { UseFields } from 'piramis-base-components/src/components/Pi/index'

import { ValidationProvider } from 'vee-validate'
import Component from 'vue-class-component'
import flatPickr from 'vue-flatpickr-component'
import { Mixins, Prop, VModel, Watch } from 'vue-property-decorator'

@Component({
  components: {
    flatPickr,
    ConfigField,
    ValidationProvider,
  }
})
export default class PeriodConditions extends Mixins(InputSetups, UseFields) {

  @VModel() scheduleCondition !: Array<Conditions>

  @Watch('scheduleConditionsModel')
  onScheduleConditionsChange() {
    this.scheduleCondition = this.scheduleConditionsModel.filter((c: any) => c.values.length && !c.values.includes(0) && this.intervalsFrontendList[c.type].isIncluded)
  }

  @Prop() period !: Period

  @Prop() initConditions !: Array<Conditions> | null

  popupActive = false

  scheduleConditionsModel: any = []

  activeConditionType: 'TimeInterval' | 'DayOfMonth' | 'DayOfWeek' | 'DateInterval' | null = null

  newTimeIntervalModel = {
    from: '',
    to: ''
  }

  daysModel = {
    'dayWeek': [],
    'dayMonth': [],
  } as Record<string, Array<string>>

  newDateIntervalModel: Array<string> = []

  conditionType = [
    { 'label': this.$t('popup_conditions_time_interval'), 'value': 'TimeInterval' },
    { 'label': this.$t('popup_conditions_day_of_week'), 'value': 'DayOfWeek' },
    { 'label': this.$t('popup_conditions_day_of_month'), 'value': 'DayOfMonth' },
    { 'label': this.$t('popup_conditions_date_interval'), 'value': 'DateInterval' },
  ]

  get modalTitle():string {
    if (this.activeConditionType === 'TimeInterval') {
      return this.$t('popup_conditions_time_interval').toString()
    }
    if (this.activeConditionType === 'DateInterval') {
      return this.$t('popup_conditions_date_interval').toString()
    }
    if (this.activeConditionType === 'DayOfMonth') {
      return this.$t('popup_conditions_day_of_month').toString()
    }
    if (this.activeConditionType === 'DayOfWeek') {
      return this.$t('popup_conditions_day_of_week').toString()
    }

    return ''
  }

  get isOkButtonDisabled():boolean {
    if (this.activeConditionType === 'TimeInterval') {
      return !this.from || !this.to
    }

    if (this.activeConditionType === 'DateInterval') {
      return !this.dateInterval
    }

    if (this.activeConditionType === 'DayOfMonth') {
      return !this.dayMonth
    }

    if (this.activeConditionType === 'DayOfWeek') {
      return !this.dayWeek.length
    }

    return false
  }

  handleEditButton(key:"DayOfMonth" | "DayOfWeek", value:Array<string>):void {
    if (key === 'DayOfMonth') {
      this.dayMonth = value
    }

    if (key === 'DayOfWeek') {
      this.dayWeek = value
    }

    this.popupActive = true
    this.activeConditionType = key
  }

  handleCancelClick():void {
    if (this.activeConditionType === 'TimeInterval') {
      this.from = this.to = ''
    }
    if (this.activeConditionType === 'DateInterval') {
      this.dateInterval = []
    }
    if (this.activeConditionType === 'DayOfMonth') {
      this.dayMonth = []
    }
    if (this.activeConditionType === 'DayOfWeek') {
      this.dayWeek = [ '' ]
    }
  }

  intervalsFrontendList: Record<string, any> = {
    'TimeInterval': {
      name: this.$t('popup_conditions_time_interval'),
      isIncluded: false,
      values: [],
    },
    'DateInterval': {
      name: this.$t('popup_conditions_date_interval'),
      isIncluded: false,
      values: []
    },
    'DayOfWeek': {
      name: this.$t('popup_conditions_day_of_week'),
      isIncluded: false,
      values: [],
      rawValues: []
    },
    'DayOfMonth': {
      name: this.$t('popup_conditions_day_of_month'),
      isIncluded: false,
      values: [],
      rawValues: []
    }
  }

  get dateInterval(): Array<string> {
    return this.newDateIntervalModel
  }

  set dateInterval(value: Array<string>) {
    this.newDateIntervalModel = value
  }

  get from(): string {
    return this.newTimeIntervalModel.from
  }

  set from(value: string) {
    this.newTimeIntervalModel.from = value
  }

  get to(): string {
    return this.newTimeIntervalModel.to
  }

  set to(value: string) {
    this.newTimeIntervalModel.to = value
  }

  get dayWeek(): any {
    return this.daysModel.dayWeek
  }

  set dayWeek(value: any) {
    this.daysModel.dayWeek = value
  }

  get dayMonth(): Array<string> {
    return this.daysModel.dayMonth
  }

  set dayMonth(value: Array<string>) {
    this.daysModel.dayMonth = value
  }

  addIntervalToFilters() {
    const interval = []
    switch (this.activeConditionType) {
      case 'TimeInterval':
        interval[0] = this.from
        interval[1] = this.to
        this.filtersconfigInputSetup('TimeInterval', interval)
        this.from = ''
        this.to = ''
        break
      case 'DateInterval':
        this.filtersconfigInputSetup('DateInterval', this.newDateIntervalModel)
        this.dateInterval = []
        break
      case 'DayOfWeek':
        const days = [ ...Array.from(new Set(this.dayWeek)) ]
        this.filtersconfigInputSetup('DayOfWeek', days)
        break
      case 'DayOfMonth':
        this.filtersconfigInputSetup('DayOfMonth', this.dayMonth)
        break
    }

    this.popupActive = false
  }

  removeIntervalItemFromLists(index: number, filter: string) {
    if ([ 'DayOfWeek', 'DayOfMonth' ].includes(filter)) {
      this.scheduleConditionsModel = this.scheduleConditionsModel.filter((f: any) => f.type !== filter)
      this.intervalsFrontendList[filter].values.splice(0, 1)
    } else {
      this.scheduleConditionsModel = this.scheduleConditionsModel.map((f: Conditions) => {
        if (f.type === filter) {
          f.values.splice(index * 2, 2)
          return f
        }
        return f
      })
      this.intervalsFrontendList[filter].values.splice(index, 1)
    }
    this.intervalsFrontendList[filter].values.length ? this.intervalsFrontendList[filter].isIncluded = true : this.intervalsFrontendList[filter].isIncluded = false
    this.scheduleConditionsModel = this.scheduleConditionsModel.filter((f: any) => f.values.length)
  }

  filtersconfigInputSetup(intervalType: string, value: any[] | string) {
    const scheduleTypes = [ 'TimeInterval', 'DateInterval', 'DayOfWeek', 'DayOfMonth' ]
    const intervals = [ 'TimeInterval', 'DateInterval' ]

    if (!this.scheduleConditionsModel.some((f: Conditions) => f.type === intervalType)) {
      this.scheduleConditionsModel.push({
        'type': intervalType,
        'values': [ ...value ]
      })
      this.intervalsFrontendList[intervalType].values.push(this.valueFormatString(intervalType, value))
    } else if (scheduleTypes.includes(intervalType)) {
      if (intervals.includes(intervalType)) {
        this.intervalsFrontendList[intervalType].values.push(this.valueFormatString(intervalType, value))
        this.intervalsFrontendList[intervalType].rawValues = value
      } else {
        this.intervalsFrontendList[intervalType].values = [ ...this.valueFormatString(intervalType, value) ]
        this.intervalsFrontendList[intervalType].rawValues = value
      }
      this.scheduleConditionsModel.map((f: any) => {
        if (f.type === intervalType && f.type === this.activeConditionType) {
          intervals.includes(intervalType) ? f.values.push(...value) : value
          return f
        }

        return f
      })
    } else {
      this.scheduleConditionsModel.map((f: any) => {
        if (f.type === intervalType) {
          return f.values = value
        }
        return f
      })
    }
  }

  valueFormatString(intervalType: string, values: any): any {
    switch (intervalType) {
      case 'DayOfMonth':
        return `${ values } ${ this.$t('chosen_month_days') }`
      case 'DayOfWeek':
        let convertWeekDays: any[] = []

        values.sort().forEach((i: any) => {
          convertWeekDays.push([
            this.$t('post_weekday_monday'),
            this.$t('post_weekday_tuesday'),
            this.$t('post_weekday_wednesday'),
            this.$t('post_weekday_thursday'),
            this.$t('post_weekday_friday'),
            this.$t('post_weekday_saturday'),
            this.$t('post_weekday_sunday'),
          ][i - 1])
        })

        return `${ this.$t('post_weekday_from') } ${ convertWeekDays.join(', ') }`
      default:
        return `${ this.$t('date_picker_from') } ${ values[0] } ${ this.$t('date_picker_to') } ${ values[values.length - 1] }`
    }
  }

  processInterval(filters: any) {
    filters.values.forEach((v: any, index: number, elements: any) => {
      if (index % 2 === 0) {
        if (filters.type === 'TimeInterval') {
          this.from = v
          this.to = elements[index + 1]
        } else {
          this.dateInterval = [ v, elements[index + 1] ]
        }

        this.activeConditionType = filters.type
        this.addIntervalToFilters()
      }
    })
  }

  created(): void {
    if (this.initConditions) {
      if (this.initConditions.some((f: Conditions) => f.type === 'DayOfMonth')) {
        this.activeConditionType = 'DayOfMonth'
        const DayOfMonthValues = this.initConditions.find((f: Conditions) => f.type === 'DayOfMonth')?.values
        this.dayMonth = DayOfMonthValues
        this.intervalsFrontendList['DayOfMonth'].isIncluded = true
        this.intervalsFrontendList['DayOfMonth'].rawValues = DayOfMonthValues
        this.addIntervalToFilters()
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'DayOfWeek')) {
        this.activeConditionType = 'DayOfWeek'
        const DayOfWeekValues = this.initConditions.find((f: Conditions) => f.type === 'DayOfWeek')?.values.map((v: any) => Number(v))
        this.dayWeek = DayOfWeekValues
        this.intervalsFrontendList['DayOfWeek'].isIncluded = true
        this.intervalsFrontendList['DayOfWeek'].rawValues = DayOfWeekValues
        this.addIntervalToFilters()
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'DateInterval')) {
        this.intervalsFrontendList['DateInterval'].isIncluded = true
        this.processInterval(this.initConditions.find((f: Conditions) => f.type === 'DateInterval'))
      }
      if (this.initConditions.some((f: Conditions) => f.type === 'TimeInterval')) {
        this.intervalsFrontendList['TimeInterval'].isIncluded = true
        this.processInterval(this.initConditions.find((f: Conditions) => f.type === 'TimeInterval'))
      }
    }
  }
}
